//category
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { categoryData, featuredSolutionData } from "../solutionSets/enum";
import { ChevronRight, Download, Home, Search } from "react-feather";
import PdfPreview from "../../../components/pdfPreview";
import BannerImg from "../../../assets/img/Img7.jpg";
import IconImg from "../../../assets/img/Img2.jpg";
import FeaturedSolution from "../../../components/FeaturedSolution";
import Card from "../../../components/Card";
import axios from "axios";
import { apiUrl } from "../../../utils/constant";

const Category = () => {
  const { categoryID } = useParams();
  const [categoryData, setCategoryData] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});
  const { state } = useLocation();

  // console.log("state", state);
  // console.log("state.category", state.category);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/category/getsolution-setsbycategory/${categoryID}`
        );

        // const response = {
        //   status: true,
        //   message: "SUCCESS",
        //   data: {
        //     category: {
        //       id: 410,
        //       name: "Winning-At-Finance-Transformations",
        //       iconPath: "Winning At Finance Transformation.jpg",
        //       bannerPath: "Winning At Finance Transformation.jpg",
        //       descp:
        //         'The document "Winning at Finance Transformation" by AppZen focuses on the use of Robotic Process Automation (RPA) and Artificial Intelligence (AI) in modern finance departments. It is intended for financial and IT decision-makers evaluating finance technology to aid digital transformation. The guide explains the differences between RPA and AI, their specific use cases, and how they can be applied to finance processes like accounts payable and invoice processing.',
        //     },
        //     whitepapers: [
        //       {
        //         id: 24,
        //         name: "1733909010191_winning-at-finance-transformation.pdf",
        //         fileType: "application/pdf",
        //         title: "winning-at-finance-transformation",
        //         description:
        //           'The document "Winning at Finance Transformation" by AppZen focuses on the use of Robotic Process Automation (RPA) and Artificial Intelligence (AI) in modern finance departments. It is intended for financial and IT decision-makers evaluating finance technology to aid digital transformation. The guide explains the differences between RPA and AI, their specific use cases, and how they can be applied to finance processes like accounts payable and invoice processing.',
        //         imagePath:
        //           "/var/www/infiniteb2b/springboot/whitepapersImages//Winning At Finance Transformation.jpg",
        //         filePath:
        //           "/var/www/infiniteb2b/springboot/whitePapers/1733909010191_winning-at-finance-transformation.pdf",
        //         status: "PENDING",
        //       },
        //     ]
        //   }
        // }

        if (response.data.status) {
          setCategoryData({
            ...response.data.data.category,
            whitepapers: response.data.data.whitepapers,
          });
        }
        // else {
        //   console.log("Failed to save PDF: " + response.data.message);
        // }
      } catch (error) {
        console.error("Error saving PDF:", error);
        console.log("An error occurred while getting Category.");
      }
    };

    getCategory();

    // const { bannerImage, iconImage, id, name, solutionSetCount } =
    //   state.category;
    // setSelectedCategory({
    //   id,
    //   name,
    //   iconImage:
    //     iconImage ??
    //     require("../../../assets/img/AI_img/Cloud Platform as a Service (PaaS).png"),
    //   bannerImage: bannerImage ?? require("../../../assets/img/Img9.jpg"),
    //   solutionSetCount,
    //   description:
    //     "Learn about the latest in marketing automation for personalized and scalable customer engagement.",
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const { imgSrc, description, solutions } = categoryData[category] || {};
  // console.log("selectedCategory", selectedCategory);
  return (
    <>
      <header className="w-full bg-white shadow-lg rounded-lg p-3 px-4">
        <div className="flex items-center gap-2 text-gray-700">
          <Link to="/home">
            <Home className="text-gray-700" size={20} />
          </Link>
          {/* <ChevronRight className="text-gray-700" size={20} /> */}
          {/* <Link
            to="/whitepapers"
            className="text-sm text-gray-700 no-underline"
          >
            WHITEPAPERS
          </Link> */}
          <ChevronRight className="text-gray-700" size={20} />
          <span className="text-gray-700 text-sm no-underline hover:cursor-pointer">
            CATEGORY
          </span>
          {/* <Link
            to={`/category/${410}`}
            className="text-gray-700 text-sm no-underline"
          >
            CATEGORY
          </Link> */}
        </div>
      </header>

      <div className="p-3">
        {/* Banner Image */}
        <div
          className="mb-3 relative w-full h-[70vh] overflow-hidden p-6 rounded-3xl object-contain"
          style={{
            backgroundImage: `url(${"https://images.pexels.com/photos/5749149/pexels-photo-5749149.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* {selectedCategory?.bannerImage && (
            // <img
            //   src={selectedCategory.bannerImage}
            //   alt={selectedCategory.name}
            //   className="w-full h-[70vh] object-cover rounded-3xl"
            // />
            <></>
          )} */}

          <div className="absolute inset-0 bg-opacity-90 sm:p-8 flex flex-col justify-end rounded-3xl">
            <div className="text-white space-y-2 w-full sm:w-2/3">
              <h1 className="mx-2 text-2xl sm:text-4xl font-bold">
                {categoryData?.name}
              </h1>
              <p className="mx-2 text-sm sm:text-base">
                {categoryData?.descp || "Description not available."}
              </p>
            </div>

            <div className="mx-2 flex items-center w-full mt-2">
              <button className="px-4 py-2 bg-teal-500 text-white rounded-full text-sm sm:text-base hover:bg-teal-600">
                Subscribe
              </button>
              <div className="text-white text-sm sm:text-base ml-4">
                {selectedCategory.solutionSetCount} WHITEPAPERS
              </div>
            </div>
          </div>
        </div>

        {/* Solutions and Search Bar */}
        <div className="bg-teal-700 w-full p-4 flex flex-col md:flex-row justify-between items-center rounded-md space-y-4 md:space-y-0">
          <div className="text-white text-lg md:text-xl font-semibold">
            {categoryData?.whitepapers?.length} SOLUTIONS
          </div>

          <div className="flex items-center bg-white rounded-full px-3 py-2 w-full max-w-xs md:max-w-sm">
            <input
              type="text"
              placeholder="Search..."
              className="bg-transparent outline-none text-gray-700 flex-grow"
            />
            <Search className="text-gray-500" size={20} />
          </div>
        </div>

        {/* PDF Preview */}
        {/* <PdfPreview /> */}

        {/* Featured Solutions */}
        {/* <FeaturedSolution /> */}

        {/* Solutions Grid */}
        <h3 className="p-3">Solutions</h3>
        {/* <div className="flex flex-wrap justify-center">
          {featuredSolutionData.map((featureCard) => (
            <Card key={featureCard.title} featureCard={featureCard} />
          ))}
        </div> */}
        <div className="flex flex-wrap justify-center">
          {categoryData?.whitepapers?.map((whitepaper) => (
            <Card
              key={whitepaper.id}
              featureCard={whitepaper}
              categaryTitle={categoryData?.name}
            />
          ))}
        </div>
      </div>
    </>
  );
  // );
};

export default Category;
