export const featuredSolutionData = [
  {
    title: "Big Data",
    imgSrc:
      "https://media.gettyimages.com/id/1495819409/photo/digital-mind-brain-artificial-intelligence-concept.jpg?s=612x612&w=gi&k=20&c=PaJl9MVRaZ4nZVhtUPV7g2cuh3UrTqqMXj1dM3B8RHk=",
    description:
      "Explore insights and trends in big data, covering analytics, data science, and more.",
    solutions: 112,
  },
  {
    title: "Internet of Things",
    imgSrc:
      "https://media.istockphoto.com/id/1387900612/photo/automation-data-analytic-with-robot-and-digital-visualization-for-big-data-scientist.jpg?s=612x612&w=0&k=20&c=50maOJU6CpVC55mYnUqtff2aiaJZ7KlmMn4jNhWD_eo=",
    description:
      "Explore insights and innovations in IoT, connecting the world of physical and digital.",
    solutions: 95,
  },
  {
    title: "Talent Management",
    imgSrc:
      "https://cdn.britannica.com/47/246247-050-F1021DE9/AI-text-to-image-photo-robot-with-computer.jpg",
    description:
      "Discover trends in managing talent effectively in today's dynamic work environment.",
    solutions: 78,
  },
  {
    title: "Marketing Automation",
    imgSrc:
      "https://www.softwebsolutions.com/wp-content/uploads/2019/12/marketing-automation-in-digital-transformation-final.jpg",
    description:
      "Learn about the latest in marketing automation for personalized and scalable customer engagement.",
    solutions: 83,
  },
  {
    title: "Cloud Platform as a Service",
    imgSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR93JxkTwTneiyCK68ofjzfzeW-cBUb1K4txg&s",
    description:
      "Insights into PaaS solutions to streamline app development and deployment in the cloud.",
    solutions: 66,
  },
  {
    title: "Account Based Marketing",
    imgSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXaS5mForztELfTif-bUf-vuVbe9isrWnn1A&s",
    description:
      "Explore strategies and tools for account-based marketing to target high-value clients.",
    solutions: 54,
  },
  {
    title: "Artificial Intelligence",
    imgSrc:
      "https://renierbotha.com/wp-content/uploads/2024/07/10day-blog-day-7_-exploring-the-potential-of-the-internet-of-things-iot.-the-image-features-a-network-of-interconnected-smart-devices-a.webp?w=765&h=380&crop=1",
    description:
      "Explore the world of AI and its applications across various industries.",
    solutions: 120,
  },
  {
    title: "Healthcare Industry",
    imgSrc:
      "https://www.appviewx.com/wp-content/uploads/2020/12/Why_Encryption_is_Critical_to_the_Healthcare_Industry-1.jpg",
    description:
      "Insights into the healthcare industry with a focus on technology and innovation in patient care.",
    solutions: 99,
  },
  {
    title: "Augmented Reality & Virtual Reality",
    imgSrc:
      "https://animost.com/wp-content/uploads/2024/02/why-virtual-reality-is-the-future-of-technology-and-beyond.jpg",
    description:
      "Discover advancements in AR and VR technologies, shaping the future of digital experiences.",
    solutions: 87,
  },
  {
    title: "Cybersecurity - Ransomware",
    imgSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFLJ6JckD34JyEy1UGZDqZZp521MVmkEQA0Q&s",
    description:
      "Learn about cybersecurity challenges and best practices to combat ransomware threats.",
    solutions: 103,
  },
  {
    title: "Big Data",
    imgSrc:
      "https://media.gettyimages.com/id/1495819409/photo/digital-mind-brain-artificial-intelligence-concept.jpg?s=612x612&w=gi&k=20&c=PaJl9MVRaZ4nZVhtUPV7g2cuh3UrTqqMXj1dM3B8RHk=",
    description:
      "Explore insights and trends in big data, covering analytics, data science, and more.",
    solutions: 112,
  },
  {
    title: "Internet of Things",
    imgSrc:
      "https://media.istockphoto.com/id/1387900612/photo/automation-data-analytic-with-robot-and-digital-visualization-for-big-data-scientist.jpg?s=612x612&w=0&k=20&c=50maOJU6CpVC55mYnUqtff2aiaJZ7KlmMn4jNhWD_eo=",
    description:
      "Explore insights and innovations in IoT, connecting the world of physical and digital.",
    solutions: 95,
  },
  {
    title: "Talent Management",
    imgSrc:
      "https://cdn.britannica.com/47/246247-050-F1021DE9/AI-text-to-image-photo-robot-with-computer.jpg",
    description:
      "Discover trends in managing talent effectively in today's dynamic work environment.",
    solutions: 78,
  },
  {
    title: "Marketing Automation",
    imgSrc:
      "https://www.softwebsolutions.com/wp-content/uploads/2019/12/marketing-automation-in-digital-transformation-final.jpg",
    description:
      "Learn about the latest in marketing automation for personalized and scalable customer engagement.",
    solutions: 83,
  },
  {
    title: "Cloud Platform as a Service",
    imgSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR93JxkTwTneiyCK68ofjzfzeW-cBUb1K4txg&s",
    description:
      "Insights into PaaS solutions to streamline app development and deployment in the cloud.",
    solutions: 66,
  },
  {
    title: "Account Based Marketing",
    imgSrc:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXaS5mForztELfTif-bUf-vuVbe9isrWnn1A&s",
    description:
      "Explore strategies and tools for account-based marketing to target high-value clients.",
    solutions: 54,
  },
];

export const categoryData = {
  "Big Data": {
    imgSrc: require("../../../assets/img/AI_img/Big Data.png"),
    description:
      "Explore insights and trends in big data, covering analytics, data science, and more.",
    solutions: 112,
  },
  "Internet of Things": {
    imgSrc: require("../../../assets/img/AI_img/Internet of Things (IoT).png"),
    description:
      "Explore insights and innovations in IoT, connecting the world of physical and digital.",
    solutions: 95,
  },
  "Talent Management": {
    imgSrc: require("../../../assets/img/AI_img/Talent Management.png"),
    description:
      "Discover trends in managing talent effectively in today's dynamic work environment.",
    solutions: 78,
  },
  "Marketing Automation": {
    imgSrc: require("../../../assets/img/AI_img/Marketing Automation.png"),
    description:
      "Learn about the latest in marketing automation for personalized and scalable customer engagement.",
    solutions: 83,
  },
  "Cloud Platform as a Service": {
    imgSrc: require("../../../assets/img/AI_img/Cloud Platform as a Service (PaaS).png"),
    description:
      "Insights into PaaS solutions to streamline app development and deployment in the cloud.",
    solutions: 66,
  },
  "Account Based Marketing": {
    imgSrc: require("../../../assets/img/AI_img/ABM (Account Based Marketing).png"),
    description:
      "Explore strategies and tools for account-based marketing to target high-value clients.",
    solutions: 54,
  },
  "Artificial Intelligence": {
    imgSrc: require("../../../assets/img/AI_img/Artificial Intelligence (AI).png"),
    description:
      "Explore the world of AI and its applications across various industries.",
    solutions: 120,
  },
  "Healthcare Industry": {
    imgSrc: require("../../../assets/img/AI_img/Healthcare Industry.png"),
    description:
      "Insights into the healthcare industry with a focus on technology and innovation in patient care.",
    solutions: 99,
  },
  "Augmented Reality & Virtual Reality": {
    imgSrc: require("../../../assets/img/AI_img/Augmented Reality & Virtual Reality.png"),
    description:
      "Discover advancements in AR and VR technologies, shaping the future of digital experiences.",
    solutions: 87,
  },
  "Cybersecurity - Ransomware": {
    imgSrc: require("../../../assets/img/AI_img/Cybersecurity - Ransomware.png"),
    description:
      "Learn about cybersecurity challenges and best practices to combat ransomware threats.",
    solutions: 103,
  },
};
