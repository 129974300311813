import React from "react";
import { Bell, Bookmark } from "react-feather";
import { useNavigate } from "react-router-dom";

const Card = ({ featureCard, categaryTitle }) => {
  const navigate = useNavigate();

  return (
    <div
      key={featureCard.title}
      className="w-full sm:w-1/2 lg:w-1/4 px-2 my-3 font-montserrat"
    >
      <div className="bg-white shadow-lg rounded-lg ">
        <div
          className="w-full h-40 bg-cover bg-center"
          style={{
            backgroundImage: `url(https://media.istockphoto.com/id/1387900612/photo/automation-data-analytic-with-robot-and-digital-visualization-for-big-data-scientist.jpg?s=612x612&w=0&k=20&c=50maOJU6CpVC55mYnUqtff2aiaJZ7KlmMn4jNhWD_eo=)`,
          }}
        ></div>
        <div className="p-4">
          <h3
            className="text-xl font-semibold mb-2  text-[#4702a2] font-montserrat inline-block border-b-2 border-[#4702a2] pb-1 w-full h-14"
            onClick={() => navigate("/casestudy/id")}
          >
            {featureCard.title}
          </h3>
          <p className="text-black line-clamp-2 font-montserrat">
            {featureCard.description}
          </p>
        </div>
        <div className="flex justify-between items-center px-4 py-2 bg-[#042C54] text-white rounded-t-2xl">
          <h4 className="text-lg font-semibold line-clamp-1 font-montserrat m-0">
            {categaryTitle}
          </h4>
          <Bookmark size={20} className="text-white" />
        </div>
      </div>
    </div>
  );
};

export default Card;
